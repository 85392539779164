<template>
  <Confirm18 :open="confirm18Open" @confirmed="confirmed18"></Confirm18>
  <DiscountPopup
    v-if="discountPopupOpen || (user && !user.subscription && user.cardLast4)"
    @update:open="discountPopupOpenUpdated"
  ></DiscountPopup>
  <template v-if="!warn.banned">
    <navigation-menu />
    <router-view />
  </template>
  <div
    class="flex justify-center items-center text-lg px-8 text-center lg:text-2xl font-bold h-full"
    v-else
  >
    <p>
      You have been permanently banned from using this service. If you think
      this is a mistake, please contact
      <a :href="`mailto:${supportEmail}?Subject=Ban%20Appeal`" class="underline"
        ><!--email_off-->{{ supportEmail
        }}<!--/email_off--></a
      >
      to appeal.
    </p>
  </div>
  <Popup
    v-if="warn.warned"
    :open="warn.warned"
    @update:open="$store.commit('TOGGLE_WARNED')"
    class="sticky z-40"
    popupStyle="bg-[#0A0D22] max-w-[800px] py-[50px] px-[30px] lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
    :closeButton="false"
    :overlayExit="false"
  >
    <div class="flex flex-col items-center gap-[25px] text-center">
      <h2 class="text-[28px] lg:text-[35px] leading-[1]">Blocked</h2>
      <p class="text-[#B1B5DB]">
        This action has been blocked (reason: {{ warn.warnReason }}). Please
        ensure that your content is appropriate and does not violate our terms
        of service. If you believe this is an error, please
        <span class="underline cursor-pointer" @click="reportError"
          >click here</span
        >.
      </p>
      <div
        class="flex justify-between text-sm font-semibold gap-x-3 text-center"
      >
        <ButtonComponent
          class="py-2 lg:py-3 w-full px-8"
          @click="$store.commit('TOGGLE_WARNED')"
          >I Understand</ButtonComponent
        >
      </div>
    </div>
  </Popup>
  <SubscribePopup
    v-if="user && !user.subscription"
    class="sticky z-50"
    :open="subscribePopupOpen"
    :text="subscribePopupText"
    @update:open="subscribePopupOpenUpdated"
  ></SubscribePopup>
  <SubscriptionExpiredPopup
    v-if="user && !user.subscription"
    class="sticky z-50"
    :open="subscriptionExpiredPopupOpen"
    :text="subscriptionExpiredPopupText"
    @update:open="subscriptionExpiredPopupOpenUpdated"
  />
  <LoginRegisterPopup
    :open="loginRegisterPopupOpen"
    :text="loginRegisterPopupText"
    @update:open="loginRegisterPopupOpenUpdated"
  />
  <NoBalancePopup
    :open="noBalancePopupOpen"
    @update:open="noBalancePopupOpenUpdated"
  />
  <Popup
    :open="genericPopupOpen"
    @update:open="genericPopupOpenUpdated"
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-[30px] lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
  >
    <div class="px-1 py-2 md:px-4 md:py-5 sm:p-6">
      <div class="flex flex-col items-center gap-[25px]">
        <h2 class="text-[30px] lg:text-[35px]">
          {{ genericPopupTitle }}
        </h2>
        <p class="text-[#B1B5DB] text-center">
          {{ genericPopupContent }}
        </p>
      </div>
    </div>
  </Popup>
</template>

<script setup>
import DiscountPopup from '@/components/DiscountPopup.vue'
import LoginRegisterPopup from '@/components/LoginRegisterPopup.vue'
import NavigationMenu from '@/components/NavigationMenu.vue'
import NoBalancePopup from '@/components/NoBalancePopup.vue'
import Popup from '@/components/Popup.vue'
import SubscribePopup from '@/components/SubscribePopup.vue'
import { useHead } from '@unhead/vue'
import { computed, watch, ref, onMounted, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ButtonComponent from '@/components/ButtonComponent.vue'
import Confirm18 from '@/components/Confirm18.vue'
import SubscriptionExpiredPopup from '@/components/SubscriptionExpiredPopup.vue'
import isCountryUs from '@/helpers/isCountryUs.js'
import { useToast } from 'vue-toast-notification'

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL

const $toast = useToast()
const $router = useRouter()
const $route = useRoute()
const $store = useStore()
const $axios = inject('axios')

const confirm18Open = ref(false)

function reportError() {
  $toast.success(`Reported for manual review`) // not really...
}

onMounted(() => {
  const isPrerendering = '__PRERENDER_INJECTED' in window
  const lowercaseUserAgent = navigator.userAgent.toLowerCase()
  const isSearchBot =
    lowercaseUserAgent.includes('googlebot') ||
    lowercaseUserAgent.includes('bingbot') ||
    lowercaseUserAgent.includes('duckduckbot')

  if (
    !$store.state.user &&
    !$store.state.confirm18 &&
    !isPrerendering &&
    !isSearchBot &&
    !window.location.href.includes('noConfirm18Popup=true')
  ) {
    confirm18Open.value = true
  }

  if (!isPrerendering) {
    window.addEventListener('load', () => {
      getUserLocation()
    })
  }
})

function confirmed18() {
  confirm18Open.value = false
  $store.commit('SET_CONFIRM_18', true)
}

const warn = computed(() => ({
  warned: $store.state.warned,
  banned: $store.state.banned,
  bannableOffenses: $store.state.bannableOffenses,
  warnReason: $store.state.warnReason,
}))

const discountPopupOpen = computed(() => $store.state.discountPopupOpen)
const mobileMenuOpen = computed(() => $store.state.mobileMenuOpen)

watch(
  mobileMenuOpen,
  () => {
    if (mobileMenuOpen.value) {
      console.log(mobileMenuOpen)
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  },
  { deep: true },
)

function discountPopupOpenUpdated(val) {
  $store.commit('SET_DISCOUNT_POPUP', {
    open: val,
  })
}

const user = computed(() => $store.state.user)

const loginRegisterPopupOpen = computed(
  () => $store.state.loginRegisterPopupOpen,
)
const loginRegisterPopupText = computed(
  () => $store.state.loginRegisterPopupText,
)

async function getUserLocation() {
  let country = ''

  if ($store.state.country) {
    country = $store.state.country
  } else {
    if ($store.state.user) {
      country = $store.state.user?.country
    } else {
      const data = await $axios.get('/location/country').then((res) => res.data)
      country = data.countryCode
    }
  }

  const isUs = isCountryUs(country)

  $store.commit('SET_COUNTRY', { country })
  $store.commit('SET_COMPANY_DETAILS', {
    corpCountry: isUs
      ? import.meta.env.VITE_CORP_COUNTRY_US
      : import.meta.env.VITE_CORP_COUNTRY,
    corpName: isUs
      ? import.meta.env.VITE_CORP_NAME_US
      : import.meta.env.VITE_CORP_NAME,
    corpAddress: isUs
      ? import.meta.env.VITE_CORP_ADDRESS_US
      : import.meta.env.VITE_CORP_ADDRESS,
    corpNumber: isUs
      ? import.meta.env.VITE_CORP_NUMBER_US
      : import.meta.env.VITE_CORP_NUMBER,
    siteDescriptor: isUs
      ? import.meta.env.VITE_SITE_DESCRIPTOR_US
      : import.meta.env.VITE_SITE_DESCRIPTOR,
  })
}

function loginRegisterPopupOpenUpdated(val) {
  $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
    open: val,
  })
}

const subscribePopupOpen = computed(() => $store.state.subscribePopupOpen)
const subscribePopupText = computed(() => $store.state.subscribePopupText)
function subscribePopupOpenUpdated(val) {
  $store.commit('SET_SUBSCRIBE_POPUP', {
    open: val,
  })
}

const subscriptionExpiredPopupOpen = computed(
  () => $store.state.subscribeExpiredPopupOpen,
)
const subscriptionExpiredPopupText = computed(
  () => $store.state.subscribeExpiredPopupText,
)
function subscriptionExpiredPopupOpenUpdated(val) {
  $store.commit('SET_SUBSCRIBE_EXPIRED_POPUP', {
    open: val,
  })
}

const noBalancePopupOpen = computed(() => $store.state.noBalancePopupOpen)
function noBalancePopupOpenUpdated(val) {
  $store.commit('SET_NO_BALANCE_POPUP', {
    open: val,
  })
}

const genericPopupOpen = computed(() => $store.state.genericPopupOpen)
const genericPopupTitle = computed(() => $store.state.genericPopupTitle)
const genericPopupContent = computed(() => $store.state.genericPopupContent)
function genericPopupOpenUpdated(val) {
  $store.commit('SET_GENERIC_POPUP', {
    open: val,
  })
}

watch(
  () => $router.currentRoute.value.path,
  (newPath, oldPath) => {
    if (!user.value) {
      if (newPath === '/' && oldPath && oldPath.startsWith('/chat')) {
        console.log('Navigating back to home, clearing chat messages...')
        $store.commit('SET_PREMADE_MODEL', null)
        $store.commit('SET_MESSAGES', [])
      } else {
        console.log(
          'Navigating to a different page, not clearing chat messages...',
        )
      }
    } else {
      console.log('User is logged in, not clearing chat messages...')
    }
  },
  { immediate: false }, // Ensure the watcher doesn't run immediately on mount
)

useHead({
  link: () => [
    {
      rel: 'canonical',
      href: `https://${import.meta.env.VITE_DOMAIN_NAME}${$router.resolve($route.name ? { name: $route.name } : $route).path}`,
    },
  ],
})
</script>

<style>
/* Your styles for App.vue go here */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

*::-webkit-scrollbar-corner,
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(85, 85, 85);
  border-radius: 15px;
}
</style>
