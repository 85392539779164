<script setup>
import { toRefs, computed } from 'vue'
import ButtonComponent from '../../../components/ButtonComponent.vue'
import magicWand from '@/assets/icons/magic-wand.svg'

const props = defineProps({
  gender: String,
  style: String,
})

const { gender, style } = toRefs(props)

const createUrl = computed(() => {
  if (style.value == 'anime') {
    if (gender.value === 'female') {
      return '/create-model?style=anime&gender=female'
    } else {
      return '/create-model?style=anime&gender=male'
    }
  }

  if (gender.value === 'female') {
    return '/create-girlfriend'
  } else {
    return '/create-boyfriend'
  }
})

const fallbackImg = computed(() => {
  if (style.value === 'realistic' && gender.value === 'female') {
    return '/banners/webp/banner-gf.webp'
  } else if (style.value === 'realistic' && gender.value === 'male') {
    return '/banners/webp/banner-bf.webp'
  } else if (style.value === 'anime' && gender.value === 'female') {
    return '/banners/webp/banner-gf-anime.webp'
  } else if (style.value === 'anime' && gender.value === 'male') {
    return '/banners/webp/banner-bf-anime.webp'
  }
})
</script>

<template>
  <div
    class="explore-banner flex items-center pt-[10px] pb-[15px] px-5 lg:px-0 lg:py-16 lg:pl-[52px] rounded-[25px] border border-[#FFFFFF0D] relative"
    style="
      background: radial-gradient(
        123.96% 123.96% at 18.89% 102.14%,
        rgba(76, 12, 81, 0.2) 0%,
        rgba(37, 6, 38, 0) 100%
      );
    "
  >
    <div
      class="lg:w-[40%] 2xl:w-[50%] flex flex-col items-start gap-4 lg:gap-[27px] relative z-10 select-none"
    >
      <h1 class="text-lg lg:text-[35px] lg:leading-[45px] select-text">
        {{ style !== 'anime' ? 'Create' : 'Generate' }} your own
        <br class="lg:hidden" />
        <span class="text-purple-500">
          AI
          {{
            style == 'anime'
              ? 'Hentai'
              : gender == 'female'
                ? 'Girlfriend'
                : 'Boyfriend'
          }}</span
        >
      </h1>
      <p class="w-full text-[16px] select-text hidden lg:block">
        <template v-if="style == 'anime'">
          Say hi to your new AI hentai character! Generate their looks and
          personality just the way you want, and with one click, watch them come
          to life. All thanks to the magic of artificial intelligence.
        </template>
        <template v-else-if="gender == 'female'">
          Your virtual AI girlfriend is waiting to meet you. Looks, personality,
          quirks... she's yours to create. One click, and your sexy AI
          girlfriend is here to make your deepest fantasies come true.
        </template>
        <template v-else-if="gender == 'male'">
          Adventure, romance, or just someone to sext with after a long day—your
          AI boyfriend is here. Design his looks, shape his personality, and
          watch him come alive. Smart, charming, and always up for a good time.
        </template>
      </p>
      <ButtonComponent
        :to="createUrl"
        class="flex items-center gap-[5px] lg:gap-[15px] py-2 px-2 lg:py-[10px] lg:pl-[11px] lg:pr-[19px] text-[14px]"
      >
        <img :src="magicWand" alt="Magic wand" class="h-5 w-5 lg:h-6 lg:w-6" />
        <span>
          {{ style !== 'anime' ? 'Create' : 'Generate' }}
          <span class="hidden lg:inline">AI</span>
          {{
            style === 'anime'
              ? 'Hentai'
              : gender === 'female'
                ? 'Girlfriend'
                : 'Boyfriend'
          }}
        </span>
        <!-- <span class="lg:hidden"> Create </span> -->
      </ButtonComponent>
    </div>
    <picture>
      <!-- Mobile first (AVIF and fallback to WEBP) -->
      <template v-if="gender === 'female' && style === 'anime'">
        <source
          srcset="/banners/avif/banner-gf-anime-sm.avif"
          media="(max-width: 1024px)"
          type="image/avif"
        />
        <source
          srcset="/banners/webp/banner-gf-anime-sm.webp"
          media="(max-width: 1024px)"
          type="image/webp"
        />
        <source
          srcset="/banners/avif/banner-gf-anime.avif"
          media="(min-width: 1024px)"
          type="image/avif"
        />
        <source
          srcset="/banners/webp/banner-gf-anime.webp"
          media="(min-width: 1024px)"
          type="image/webp"
        />
      </template>
      <template v-else-if="gender === 'female' && style === 'realistic'">
        <source
          srcset="/banners/avif/banner-gf-sm.avif"
          media="(max-width: 1024px)"
          type="image/avif"
        />
        <source
          srcset="/banners/webp/banner-gf-sm.webp"
          media="(max-width: 1024px)"
          type="image/webp"
        />
        <source
          srcset="/banners/avif/banner-gf.avif"
          media="(min-width: 1024px)"
          type="image/avif"
        />
        <source
          srcset="/banners/webp/banner-gf.webp"
          media="(min-width: 1024px)"
          type="image/webp"
        />
      </template>
      <template v-else-if="gender === 'male' && style === 'anime'">
        <source
          srcset="/banners/avif/banner-bf-anime-sm.avif"
          media="(max-width: 1024px)"
          type="image/avif"
        />
        <source
          srcset="/banners/webp/banner-bf-anime-sm.webp"
          media="(max-width: 1024px)"
          type="image/webp"
        />
        <source
          srcset="/banners/avif/banner-bf-anime.avif"
          media="(min-width: 1024px)"
          type="image/avif"
        />
        <source
          srcset="/banners/webp/banner-bf-anime.webp"
          media="(min-width: 1024px)"
          type="image/webp"
        />
      </template>
      <template v-else-if="gender === 'male' && style === 'realistic'">
        <source
          srcset="/banners/avif/banner-bf-sm.avif"
          media="(max-width: 1024px)"
          type="image/avif"
        />
        <source
          srcset="/banners/webp/banner-bf-sm.webp"
          media="(max-width: 1024px)"
          type="image/webp"
        />
        <source
          srcset="/banners/avif/banner-bf.avif"
          media="(min-width: 1024px)"
          type="image/avif"
        />
        <source
          srcset="/banners/webp/banner-bf.webp"
          media="(min-width: 1024px)"
          type="image/webp"
        />
      </template>

      <!-- No fallback, just AVIF and WEBP support -->
      <img
        :srcset="fallbackImg"
        alt="Banner"
        class="inline absolute top-0 right-0 h-[100%] rounded-tr-[25px] select-none rounded-br-[25px] object-cover"
        loading="eager"
        fetchpriority="high"
        decoding="async"
      />
    </picture>
  </div>
</template>
