<script setup>
import { ref, inject, onMounted, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'
import ButtonComponent from './ButtonComponent.vue'
import IntlTelInput from 'intl-tel-input/vueWithUtils'
import 'intl-tel-input/styles'
import { useMixpanel } from '@/composables/mixpanel'
import ErrorAlert from './ErrorAlert.vue'

const errorMap = [
  'Invalid number',
  'Invalid country code',
  'Number is too short',
  'Number is too long',
  'Invalid number',
]

const mixpanel = useMixpanel()

const $store = useStore()
const $toast = useToast()
const $axios = inject('axios')
const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})

const emit = defineEmits(['finishRegister', 'openPhoneNumberDialog'])

const resendCooldown = ref(null)
let interval

const d1Ref = ref(null)
const d2Ref = ref(null)
const d3Ref = ref(null)
const d4Ref = ref(null)
const error = ref(null)
const phoneNumber = ref('')
const isPhoneNumberValid = ref(null)
const phoneNumberErrorCode = ref(null)
const intlTelInputRef = ref(null)

const user = computed(() => $store.state.user)

const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

watch(d1Ref, (newVal) => {
  if (newVal) {
    d1Ref.value?.focus()
  }
})

const handlePress = (e) => {
  if (!keysAllowed.includes(e.key)) {
    return e.preventDefault()
  }

  e.target.value = e.key

  const nextSibling = e.target.nextSibling

  if (nextSibling) {
    e.preventDefault()
    nextSibling.focus()
  } else {
    e.target.blur()
  }

  const value =
    d1Ref.value.value +
    d2Ref.value.value +
    d3Ref.value.value +
    d4Ref.value.value

  if (value.length !== 4) return
  verifyPhoneNumber(value)
}

const handleDelete = (e) => {
  if (e.target.value !== '') {
    return (e.target.value = '')
  }

  const previousSibling = e.target.previousSibling

  if (previousSibling) {
    e.preventDefault()
    previousSibling.focus()
    previousSibling.value = ''
  }
}

const handlePaste = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData
  const pastedText = clipboardData.getData('text').trim()

  if (pastedText.split('').some((c) => isNaN(c)) || pastedText === '') {
    return e.preventDefault()
  }

  d1Ref.value.value = pastedText[0] || ''
  d2Ref.value.value = pastedText[1] || ''
  d3Ref.value.value = pastedText[2] || ''
  d4Ref.value.value = pastedText[3] || ''

  e.target.blur()

  const value =
    d1Ref.value.value +
    d2Ref.value.value +
    d3Ref.value.value +
    d4Ref.value.value

  if (value.length !== 4) return
  verifyPhoneNumber(value)
}

const handleFilterLetters = (e) => {
  if (!keysAllowed.includes(e.key)) {
    e.target.value = ''
    e.preventDefault()
  }
}

async function sendSmsVerificationCode(e) {
  if (!isPhoneNumberValid.value) {
    const errorMessage = errorMap[phoneNumberErrorCode.value || 0]
    return (error.value = errorMessage)
  }

  e.preventDefault()
  const loader = $loading.show()

  try {
    const data = await $axios({
      method: 'post',
      url: '/sms-verification/send',
      data: {
        phoneNumber: phoneNumber.value,
      },
    }).then((res) => res.data)

    $store.commit('SET_USER', {
      ...user.value,
      phoneNumber: phoneNumber.value,
    })
  } catch (e) {
    const message = e?.response?.data?.message
    error.value = message || 'Unable to perform action. Please try again later.'
  } finally {
    loader.hide()
  }
}

async function verifyPhoneNumber(otp) {
  const loader = $loading.show()

  try {
    const data = await $axios({
      method: 'post',
      url: '/sms-verification/verify',
      data: {
        otp,
      },
    }).then((res) => res.data)

    mixpanel.track('sms_verification_success')

    $store.commit('SET_USER', {
      ...$store.state.user,
      smsVerificationRequired: false,
    })
    emit('finishRegister')
  } catch (e) {
    mixpanel.track('sms_verification_fail')
    const message = e?.response?.data?.message
    error.value = message || 'Unable to perform action. Please try again later.'
    d1Ref.value.value = ''
    d2Ref.value.value = ''
    d3Ref.value.value = ''
    d4Ref.value.value = ''
  } finally {
    loader.hide()
  }
}

async function resendSms() {
  const loader = $loading.show()
  mixpanel.track('sms_verification_resend_sms')

  try {
    const res = await $axios({
      method: 'post',
      url: '/sms-verification/resend',
    })
    const message = res.data.message
    $toast.success(message)
    resendCooldown.value = 30
    interval = setInterval(() => {
      if (resendCooldown.value > 0) {
        resendCooldown.value--
        if (resendCooldown.value === 0) {
          clearInterval(interval)
          resendCooldown.value = null
        }
      }
    }, 1000)
  } catch (e) {
    const message = e?.response?.data?.message
    $toast.error(message || 'Unable to perform action. Please try again later.')
  } finally {
    loader.hide()
  }
}

const handleVerify = () => {
  const value =
    d1Ref.value.value +
    d2Ref.value.value +
    d3Ref.value.value +
    d4Ref.value.value

  if (value.length !== 4) return

  verifyPhoneNumber(value)
}

const handleResendSms = () => {
  if (resendCooldown.value !== null) return
  resendSms()
  resendCooldown.value = null
}

const handleOpenPhoneNumberModal = () => {
  emit('openPhoneNumberDialog', true)
}

const handleNumberChange = (newNumber) => {
  phoneNumber.value = newNumber
}

mixpanel.track('sms_verification_requested')
console.log('SMS VERIFICATION REQUESTED!!!')
</script>

<template>
  <div>
    <p class="text-center mb-[46px] lg:mb-[30px] text-sm text-[#B1B5DB]">
      Multiple signup attempts from this origin have been detected. Please
      verify your phone number to continue with your free trial.
    </p>
    <form
      v-if="!user.phoneNumber"
      @focusin="error = null"
      @submit.prevent="sendSmsVerificationCode"
    >
      <IntlTelInput
        ref="intlTelInputRef"
        :strictMode="true"
        @changeErrorCode="phoneNumberErrorCode = $event"
        @changeValidity="isPhoneNumberValid = $event"
        @changeNumber="handleNumberChange"
        :options="{
          strictMode: true,
          initialCountry: user.countryCode,
          containerClass: 'w-full',
        }"
      />
      <ButtonComponent class="w-full mt-5 py-3">Send Code</ButtonComponent>
    </form>
    <form v-else @focusin="error = null" @submit.prevent="handleVerify">
      <p class="text-center my-4 text-sm text-[#B1B5DB]">
        Verification code sent to {{ user.phoneNumber }}
      </p>
      <div class="flex justify-between mb-[56px] lg:mb-5 gap-[10px] lg:gap-4">
        <input
          ref="d1Ref"
          class="text-center w-[30%] max-w-[120px] aspect-square text-[36px] rounded-[15px] lg:rounded-[25px] border-0 bg-[#0A0D22] text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          maxlength="1"
          @keypress="handlePress"
          @keydown.delete="handleDelete"
          @paste="handlePaste"
          @input="handleFilterLetters"
          pattern="\d*"
          type="number"
          autofocus
        />
        <input
          ref="d2Ref"
          class="text-center w-[30%] max-w-[120px] aspect-square text-[36px] rounded-[15px] lg:rounded-[25px] border-0 bg-[#0A0D22] text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          maxlength="1"
          @keypress="handlePress"
          @keydown.delete="handleDelete"
          @paste="handlePaste"
          @input="handleFilterLetters"
          pattern="\d*"
          type="number"
        />
        <input
          ref="d3Ref"
          class="text-center w-[30%] max-w-[120px] aspect-square text-[36px] rounded-[15px] lg:rounded-[25px] border-0 bg-[#0A0D22] text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          maxlength="1"
          @keypress="handlePress"
          @keydown.delete="handleDelete"
          @paste="handlePaste"
          @input="handleFilterLetters"
          pattern="\d*"
          type="number"
        />
        <input
          ref="d4Ref"
          class="text-center w-[30%] max-w-[120px] aspect-square text-[36px] rounded-[15px] lg:rounded-[25px] border-0 bg-[#0A0D22] text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          maxlength="1"
          @keypress="handlePress"
          @keydown.delete="handleDelete"
          @paste="handlePaste"
          @input="handleFilterLetters"
          pattern="\d*"
          type="number"
        />
      </div>
      <div
        class="text-sm text-center mt-0 lg:mt-5 mb-[46px] lg:mb-[31px] text-[#B1B5DB]"
      >
        Didn't receive the code?
        <a
          :class="`${!resendCooldown ? 'text-[#CC47FF]' : 'text-neutral-500 cursor-default'}`"
          @click.prevent="handleResendSms"
          href="#"
        >
          Send again
        </a>
        <span v-if="resendCooldown">
          (00:{{
            resendCooldown < 10 ? '0' + resendCooldown : resendCooldown
          }})</span
        >
        or
        <br />
        <a
          class="text-[#CC47FF]"
          @click.prevent="handleOpenPhoneNumberModal"
          href="#"
          >Change phone number</a
        >
      </div>
      <ButtonComponent
        type="submit"
        class="flex w-full justify-center py-[14px]"
      >
        Verify
      </ButtonComponent>
    </form>
    <ErrorAlert v-if="error" :errorText="error" />
    <!-- <div class="mt-[10px] flex flex-col gap-4">
      <ButtonComponent
        variant="secondary"
        :borderVisible="false"
        class="py-[14px] block lg:hidden"
        href="mailto:"
        >Open Mail</ButtonComponent
      >
      <a
        class="w-full py-5 rounded-[15px] border border-[#141A3D] hidden sm:flex items-center justify-center gap-4"
        href="https://gmail.com"
        rel="nofollow noreferrer noopener"
        target="_blank"
      >
        <img class="h-8 w-8" :src="gmail" />
        <div class="w-[105px]">Open Gmail</div>
      </a>
      <a
        class="w-full py-5 rounded-[15px] border border-[#141A3D] hidden sm:flex items-center justify-center gap-4"
        href="https://yahoo.com"
        rel="nofollow noreferrer noopener"
        target="_blank"
      >
        <img class="h-8 w-8" :src="yahoo" />
        <div class="w-[105px]">Open Yahoo</div>
      </a>
      <a
        class="w-full py-5 rounded-[15px] border border-[#141A3D] hidden sm:flex items-center justify-center gap-4"
        href="https://outlook.com"
        rel="nofollow noreferrer noopener"
        target="_blank"
      >
        <img class="h-8 w-8" :src="outlook" />
        <div class="w-[105px]">Open Outlook</div>
      </a>
      <a
        class="w-full py-5 rounded-[15px] border border-[#141A3D] hidden sm:flex items-center justify-center gap-4"
        href="https://icloud.com"
        rel="nofollow noreferrer noopener"
        target="_blank"
      >
        <img class="h-8 w-8" :src="icloud" />
        <div class="w-[105px]">Open iCloud</div>
      </a>
    </div> -->
  </div>
</template>
